var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('app-text-search-field',{on:{"input":function($event){return _vm.handleSearchInput($event)}}})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12","md":"8"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"bottom":"","large":"","color":"primary"},on:{"click":function($event){_vm.handleStoreCustomer((_vm.id = null))}}},[_vm._v("Novo")])],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users.data,"loading":_vm.loading,"server-items-length":_vm.users.total,"options":_vm.options,"hide-default-footer":"","disable-sort":""},on:{"update:options":[function($event){_vm.options=$event},function($event){return _vm.handleTableUpdate($event)}]},scopedSlots:_vm._u([{key:"item.phone_wa",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-whatsapp")]),_vm._v(" "+_vm._s(item.phone_wa)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center py-2"},[_c('div',[(item.company_user.is_owner != 1)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-account")]):_vm._e(),(item.company_user.is_owner != 0)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-account-settings")]):_vm._e()],1),_c('div',[(item.name)?_c('span',[_vm._v(" "+_vm._s(item.name)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.email)+" ")]),_c('br'),_c('v-chip',{attrs:{"x-small":"","color":_vm.statuses[item.company_user.status].color}},[_vm._v(_vm._s(_vm.statuses[item.company_user.status].text))])],1)])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.company_user.status == 'invited')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.updateInvitation('refreshToken', item.company_user)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-refresh ")])],1)]}}],null,true)},[_c('span',[_vm._v("Reenviar Convite")])]):_vm._e(),(item.company_user.status == 'invited')?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.updateInvitation('deleteToken', item.company_user)}}},'v-btn',attrs,false),on),[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',[_vm._v(" mdi-delete-outline ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Cancelar Convite")])]):_vm._e()]}},{key:"loading",fn:function(){return [_vm._v("Carregando.. ")]},proxy:true},{key:"no-data",fn:function(){return [_vm._v("Nenhum item encontrado")]},proxy:true}],null,true)}),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.users.last_page},on:{"input":function($event){return _vm.select($event)}},model:{value:(_vm.searchParams.page),callback:function ($$v) {_vm.$set(_vm.searchParams, "page", $$v)},expression:"searchParams.page"}})],1)],1)],1),_c('StoreUserForm',{ref:"StoreUserForm",on:{"store":function($event){return _vm.select(_vm.searchParams.page)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }