<template>
  <v-navigation-drawer
    clipped
    temporary
    app
    right
    v-model="dialog"
    width="550px"
  >
    <validation-observer ref="loginValidation">
      <v-card-title> Adicionar Usuário </v-card-title>
      <v-card-text>
        <form @submit.prevent>
          <validation-provider name="email" rules="email" #default="{ errors }">
            <v-row class="mt-6">
              <v-col md="12">
                <app-text-field
                  v-model="email"
                  label="Email"
                  :state="errors.length > 0 ? false : null"
                  :error-messages="errors[0]"
                />
              </v-col>
            </v-row>
          </validation-provider>
        </form>
      </v-card-text>

      <v-card-actions v-if="token">
        <v-btn
          class="text-capitalize"
          @click="copyLink"
          block
          outlined
          depressed
          raised
        >
          {{ token }}
          <v-icon class="ml-auto">mdi-content-copy</v-icon>
        </v-btn>
      </v-card-actions>
    </validation-observer>

    <template v-slot:append>
      <div class="pa-4" style="background-color: #f5f5f5">
        <v-btn
          v-if="!token"
          @click="sendInvitation()"
          class="text-capitalize mr-2"
          color="primary"
          :loading="loading"
          >Enviar Convite</v-btn
        >
        <v-btn @click="dialog = false" text class="text-capitalize"
          >Cancelar</v-btn
        >
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { email } from "@/utils/validations";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      email: null,
      loading: false,
      dialog: false,
      token: null,
    };
  },
  computed: {},
  methods: {
    open() {
      this.dialog = true;
    },
    sendInvitation() {
      this.loading = true;
      this.$http
        .store("invitation/invitation", { email: this.email })
        .then((response) => {
          this.token = `${process.env.BASE_URL}convite/${response.service.token}`;

          this.$emit("store");
          this.loading = false;
        });
    },
    copyLink: function() {
      navigator.clipboard.writeText(this.token);
    },
  },
};
</script>

<style scoped></style>
