<template>
  <div>
    <v-row>
      <v-col cols="12" md="4">
        <app-text-search-field @input="handleSearchInput($event)" />
      </v-col>
      <v-col class="text-right" cols="12" md="8">
        <v-btn
          class="text-capitalize"
          bottom
          large
          color="primary"
          @click="handleStoreCustomer((id = null))"
          >Novo</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="users.data"
          :loading="loading"
          :server-items-length="users.total"
          :options.sync="options"
          hide-default-footer
          disable-sort
          @update:options="handleTableUpdate($event)"
        >
          <!-- :items-per-page="20" -->
          <!-- :options.sync="options" -->
          <template v-slot:[`item.phone_wa`]="{ item }">
            <v-icon class="mr-2" color="primary">mdi-whatsapp</v-icon>
            {{ item.phone_wa }}
          </template>
          <template v-slot:[`item.name`]="{ item }">
            <div class="d-flex align-center py-2">
              <div>
                <v-icon v-if="item.company_user.is_owner != 1" class="mr-2" color="primary" >mdi-account</v-icon >
                <v-icon v-if="item.company_user.is_owner != 0"class="mr-2"color="primary">mdi-account-settings</v-icon>
              </div>
              <div>
                <span v-if="item.name"> {{ item.name }} </span>
                <span v-else> {{ item.email }} </span>
                <br />
                <!-- <small class="text--secondary"> -->
                <v-chip
                  x-small
                  :color="statuses[item.company_user.status].color"
                  >{{ statuses[item.company_user.status].text }}</v-chip
                >
              </div>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="item.company_user.status == 'invited'" left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="updateInvitation('refreshToken', item.company_user)"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon> mdi-refresh </v-icon></v-btn
                >
              </template>
              <span>Reenviar Convite</span>
            </v-tooltip>
            <v-tooltip v-if="item.company_user.status == 'invited'" left>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="updateInvitation('deleteToken', item.company_user)"
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-btn icon> <v-icon> mdi-delete-outline </v-icon></v-btn>
                </v-btn>
              </template>
              <span>Cancelar Convite</span>
            </v-tooltip>
          </template>
          <template v-slot:loading>Carregando.. </template>
          <template v-slot:no-data>Nenhum item encontrado</template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="searchParams.page"
            :length="users.last_page"
            @input="select($event)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <StoreUserForm ref="StoreUserForm" @store="select(searchParams.page)" />
  </div>
</template>

<script>
import StoreUserForm from "@/components/users/forms/StoreUserForm";

export default {
  components: { StoreUserForm },

  data: () => ({
    statuses: {
      active: {
        text: "Ativo",
        color: "primary",
      },
      inactive: {
        text: "Inativo",
        color: "disable",
      },
      invited: {
        text: "convidado",
        color: "info",
      },
    },

    headers: [
      { text: "Nome", align: "start", value: "name" },
      // { text: "Whatsapp", align: "start", value: "phone_wa" },
      // { text: "Endereço", align: "start", value: "main_address.address" },
      // { text: "Qnt. Vendas", align: "left", value: "count_sales" },
      { text: "", align: "end", value: "actions" },
    ],

    options: {},

    loading: false,

    searchParams: {
      page: 1,
      orderBy: "name",
      orderDirection: "asc",
    },

    users: {
      data: [],
    },
  }),

  created() {
    this.select();
  },

  methods: {
    async select(page = 1) {
      this.loading = true;

      this.searchParams.page = page;

      await this.$http
        .index("users/user", this.searchParams)
        .then((response) => {
          this.users = response.users;
        });

      this.loading = false;
    },

    handleTableUpdate(options) {
      this.options = options;
      //this.searchParams.page = options.page
      this.searchParams.orderBy = options.sortBy[0];
      this.searchParams.orderDirection = options.sortDesc[0] ? "desc" : "asc";
      this.select(options.page);
    },

    handleSearchInput(text) {
      this.searchParams.text = text;
      this.select();
    },

    handleStoreCustomer(id = null) {
      this.$refs.StoreUserForm.open(id);
    },
    updateInvitation(operation, company_user) {
      this.$http
        .update("invitation/invitation", company_user.id, {
          company_user: company_user,
          operation: operation,
        })
        .then((response) => {
          this.select();
        });
    },
  },
};
</script>

<style></style>
